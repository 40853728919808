/*
 Copyright (C) 2022-present Wong Chun Yat (wcyat)

 This program is free software: you can redistribute it and/or modify
 it under the terms of the GNU Affero General Public License as
 published by the Free Software Foundation, either version 3 of the
 License, or (at your option) any later version.

 This program is distributed in the hope that it will be useful,
 but WITHOUT ANY WARRANTY; without even the implied warranty of
 MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 GNU Affero General Public License for more details.

 You should have received a copy of the GNU Affero General Public License
 along with this program.  If not, see <https://www.gnu.org/licenses/>.
 */

import { useEffect } from "react";
import { useSettings, useUser } from "../../components/AppContextProvider";
import { subscribe } from "../../lib/notifications";

export function useSubscribeNotifications() {
    const [user] = useUser();
    const [settings] = useSettings();

    useEffect(() => {
        if (user && settings.notifications) {
            subscribe();
        }
    }, [user, settings.notifications]);
}
