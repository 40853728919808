/*
 Copyright (C) 2022-present Wong Chun Yat (wcyat)

 This program is free software: you can redistribute it and/or modify
 it under the terms of the GNU Affero General Public License as
 published by the Free Software Foundation, either version 3 of the
 License, or (at your option) any later version.

 This program is distributed in the hope that it will be useful,
 but WITHOUT ANY WARRANTY; without even the implied warranty of
 MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 GNU Affero General Public License for more details.

 You should have received a copy of the GNU Affero General Public License
 along with this program.  If not, see <https://www.gnu.org/licenses/>.
 */

export const colors = {
    link: "#3498db",
    blue: "#34aadc",
    grey: "#aca9a9",
    yellow: "#f5bd1f",
};

export const css = {
    link: `text-[${colors.link}] !no-underline hover:!underline cursor-pointer`,
    svgwhite: "invert sepia-0 saturate-0 hue-rotate-[93deg] !brightness-[103%]",
    smallBtn:
        "!rounded-[5px] !m-0 !text-metahkg-grey !p-0 !pl-[10px] !pr-[10px] !pt-[3px] !pb-[3px] !min-w-0",
    colors: {
        link: "text-[#3498db]",
        blue: "text-[#34aadc]",
        grey: "text=[#aca9a9]",
        yellow: "text=[#f5bd1f]",
    },
};
